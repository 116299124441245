@keyframes pulse {
  0% { box-shadow:0 0 var(--shadow) var(--pulse-color), inset 0 0 5px var(--pulse-color); }
  50% { box-shadow:0 0 calc(var(--shadow) * 2) var(--pulse-color), inset 0 0 7px var(--pulse-color); }
  100% { box-shadow:0 0 var(--shadow) var(--pulse-color), inset 0 0 5px var(--pulse-color); }
} 

@keyframes ripples {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: 186vw;
    height: 186vw;
    opacity: 0;
  }
}



#root {
  --row-height: 60px;
  --disc-size: 35px;
  /* --table-padding-vertical: 15px;
  --table-padding-horisontal: 5px; */
  --pulse-color-win: #149e14; 
  --pulse-color-loss: #f82c2c;
  --pulse-color-draw: #aca9a9;
  /* --reason-height: 29.4px;
  --reason-width: 22.05px;
  --control-height: 23.5px;
  --control-width: 75px; 
  --font-size: 13px;
  --default-font-size: 15px;
  --shadow: 5px; */
  
  background-color: #312e2b;
  /* min-height: 100vh; */
  /* scrollbar-width: none; */
}

/* @media only screen and (max-width: 500px) { */
  #root {
    --row-height: 60px;
    --disc-size: 35px;
    --table-padding-vertical: 10px;
    --table-padding-horisontal: 3.33px;
    --reason-height: 18px;
    --reason-width: 13.5px;
    --control-height: 31px;
    --control-width: 23.25px; 
    --font-size: 10px;
    --default-font-size: 15px;
    --shadow: 2.5px;
  }

  .time-control-bullet {
    width: 35px;
    height: 11px;
  }
  
  .time-control-blitz {
    margin: 5px 0px 7px 0px;
    width: 35px;
    height: 19.2px;
  }
  
  div.time-control-bullet {
    margin: 15px 0px 10px 0px;
    width: 35px;
    height: 11px;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
  }

  svg.time-control-bullet {
    margin-left: 5px;
    margin-top: -10px;
    width: 30px;
    height: 35px;
    transform: rotate(90deg)
  }
  
  div.time-control-blitz {
    margin: 15px 0px 7px 0px;
    width: 35px;
    height: 19.2px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  svg.time-control-blitz {
    margin-left: 5px;
    margin-top: -5px;
    width: 25px;
    height: 30px;
  }

  div.time-control-classic {
    margin: 15px 0px 7px 0px;
    width: 35px;
    height: 19.2px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  svg.time-control-classic {
    margin-top: -7px;
    width: 35px;
    height: 45px;
  }

  .table-text {
    width: calc(100% - 64px);
  }

  .rating {
    width: 65px;
  }

  .rank {
    width: 40px;
    padding: 0 5px 0 10px;
  }

  .first-table-element {
    padding: 0 5px 0 10px;
  }


/* } */

div.table-container {
  left: 1%;
  border-radius: 0.5rem;
  max-width: calc(500px * 0.98);
  position: absolute;
  top: var(--offset);
  /* background-color: #312e2b; */
}

.list {
  background-color: #312e2b;
  border-radius: 0.5rem;
  /* border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem; */
  scrollbar-width: none;
}

.list::-webkit-scrollbar {
  display: none;
}

div.table-row {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #312e2b;
  height: var(--row-height);
  cursor: default;
  background-color: #272522;
}

.ripple {
  position: absolute;
  background: #fff;
  transform: translate(-50%,-50%);
  pointer-events: none;
  border-radius: 50%;
}

.ripple-container {
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  display: inline-block;
  background-color: transparent;
  width: 100%;
  height: 100%;
  cursor: default;
  z-index: 110;
  cursor: pointer;
}

.filters-container {
  position: absolute;
  display: flex;
  /* justify-content: space-between; */
  top: 55px;
  height: 60px;
  width: 98vw;
  max-width: calc(500px * 0.98);
  border-radius: 0.5rem;
}

.filters {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  width: fit-content;
  min-width: 75px;
  background-color: #272522;
  border-radius: 0.5rem;
  cursor: pointer;
}

.filters.xot {
  position: absolute;
  right: 0;
  width: calc(78% - 190px);
}

.control-container {
  margin-top: 5px;
}

div.text-filters {
  padding-left: 10px;
  color: #b4b3b2;
  font-weight: 600;
  text-align: left;
  line-height: 60px;
  width: calc(20% + 107px);
}

div.direct-message {
  display: flex;
  align-items: center;
  width: 35px;
  cursor: pointer;
} 

/* div.direct-message svg {
  width: 35px;
}  */

div.xot-button {
  min-width: 50px;
  height: 50px;
  height: calc(var(--row-heght) - 1);
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 5px;
}

div.xot-button button {
  width: 60px;
  margin-left: 5px;
  height: 30px;
  border-radius: 0.25rem;
}

div.table-row:hover {
  background-color: #1f1e1b;
}

div.extended {
  background-color: #1f1e1b;
  position: relative;
  overflow: hidden;
  /* display: inline-block; */
}

.first-table-element {
  padding: 0 5px 0 10px;
  width: fit-content;
}

/* .time-control-bullet {
  width: 50px;
  height: 15.6px;
} */

.time-control-blitz {
  --margin-top: 10px;
  --margin-bottom: 5px;
  width: calc((var(--row-height) - var(--font-size) * 1.2 - var(--margin-top) - var(--margin-bottom) - 6px) * (var(--control-width) / var(--row-height)));
  height: calc(var(--row-height) - var(--font-size) * 1.2 - var(--margin-top) - var(--margin-bottom) - 6px);
}

/* div.time-control-bullet {
  margin: 10px 0px 15px 0px;
  width: 50px;
  height: 15.6px;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
} */

div.time-control-blitz {
  --margin-top: 10px;
  --margin-bottom: 5px;
  margin: var(--margin-top) 0px var(--margin-bottom) 0px;
  width: calc((var(--row-height) - var(--font-size) * 1.2 - var(--margin-top) - var(--margin-bottom) - 6px) * (var(--control-width) / var(--row-height)));
  height: calc(var(--row-height) - var(--font-size) * 1.2 - var(--margin-top) - var(--margin-bottom) - 6px);
  background-size: contain;
  background-repeat: no-repeat;
}

.small-text {
  font-size: var(--font-size);
  line-height: 1.2;
  color: #9e9e9e;
  text-align: center;
  margin: 3px 0px 5px 0px;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

div.reason-container {
  display: block;
  width: var(--reason-width);
  height: var(--row-height);
  max-height: var(--row-height);
  margin: 0 10px 0 5px;
}

div.analysys {
  display: flex;
  width: calc(var(--disc-size) * 0.75);
  height: var(--row-height);
  max-height: var(--row-height);
  margin: 0 10px 0 5px;
}

div.table-text.adl{
  margin-top: 1px;
  font-weight: 400;
  font-size: 12px;
  width: calc(var(--disc-size) * 0.75);
  color: white;
  margin: 0 10px 0 5px;
}    

div.reason {
  margin: 10px 0px 0px 0px;
  width: var(--reason-width);
  height: var(--reason-height);
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
}

.reason-img {
  width: var(--reason-width);
  height: var(--reason-height);
}

div.reason-loss {
  margin: 10px 0px 0px 0px;
  width: calc((var(--reason-height) - var(--font-size) * 1.2) * (var(--reason-width) / var(--reason-height)));
  height: calc(var(--reason-height) - var(--font-size) * 1.2);
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
}

div.reason-draw {
  margin: 10px 0px 0px 0px;
  width: calc((var(--reason-height) - var(--font-size) * 1.2) * (var(--reason-width) / var(--reason-height)));
  height: calc(var(--reason-height) - var(--font-size) * 1.2);
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
}

.result-win { 
  background-color:transparent;
  --pulse-color: var(--pulse-color-win);
  width: var(--disc-size);
  height: var(--disc-size);
  border-radius: 50%;
  box-shadow: 0 0 5px var(--pulse-color), inset 0 0 5px var(--pulse-color);
  animation: pulse 2s linear 1s infinite;
}

.result-loss { 
  background-color:transparent;
  --pulse-color: var(--pulse-color-loss);
  width: var(--disc-size);
  height: var(--disc-size);
  border-radius: 50%;
  box-shadow: 0 0 5px var(--pulse-color), inset 0 0 5px var(--pulse-color);
  animation: pulse 2s linear 1s infinite;
}

.result-draw { 
  background-color:transparent;
  --pulse-color: var(--pulse-color-draw);
  width: var(--disc-size);
  height: var(--disc-size);
  border-radius: 50%;
  box-shadow: 0 0 5px var(--pulse-color), inset 0 0 5px var(--pulse-color);
  animation: pulse 2s linear 1s infinite;
}

.score-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  max-height: var(--row-height);
}

.score-container-black {
  width: var(--disc-size);
  height: var(--disc-size);
  margin: 5px 0px 5px 0px;
  position: relative;
  border-radius: 50%;
  background-color: black;
  padding: 0px;
}

.score-container-white {
  width: var(--disc-size);
  height: var(--disc-size);
  margin: 5px 0px 5px 0px;
  position: relative;
  border-radius: 50%;
  background-color: white;
  padding: 0px;
}

.disc-count-black {
  color: white;
  line-height: var(--disc-size); 
  font-size: calc(var(--disc-size)/3);
  font-weight: bold;
  margin: 0;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
  min-width: var(--disc-size);
  width: calc(var(--disc-size) + 1.5px);
  height: calc(var(--disc-size) + 1.5px);
  padding: 0px;
}

.disc-count-white {
  color: black;
  line-height: var(--disc-size);
  font-size: calc(var(--disc-size)/3);
  font-weight: bold;
  margin: 0;
  text-align: center;
  background-color: transparent;
  outline: none;
  border: none;
  min-width: var(--disc-size);
  width: calc(var(--disc-size) + 1.5px);
  height: calc(var(--disc-size) + 1.5px);
  padding: 0px;
}

.table-text {
  color: #b4b3b2;
  width: calc(100% - 64px);
  max-width: 200px;
  font-weight: 600;
  display: block;
  /* justify-content: left; */
  /* align-items: left; */
  text-align: left;
  line-height: var(--row-height);
  max-height: var(--row-height);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.table-nick {
  color: #b4b3b2;
  width: calc(100% - 64px);
  max-width: 150px;
  font-weight: 600;
  display: block;
  /* justify-content: left; */
  /* align-items: left; */
  text-align: left;
  line-height: var(--row-height);
  max-height: var(--row-height);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.table-text.split-top{
  line-height: calc(var(--row-height) / 2);
  max-width: calc(100% - 49px);
}

.table-text.split-bottom{
  line-height: calc(var(--row-height) / 2.5);
  font-weight: 400;
}

.rank {
  width: 40px;
  padding: 0 5px 0 10px;
}

.archive-button-container {
  width: 45px;
  height: 30px;
}
.archive-button {
  min-width: 45px;
  height: 30px;
  border-radius: 0.25rem;
}
.archive-button-red {
  min-width: 45px;
  height: 30px;
  border-radius: 0.25rem;
  background-color: #8b0100;
}





