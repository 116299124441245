/* @import "~materialize-css/dist/css/materialize.min.css"; */
@import "./css/ratingPage.css";
@import "./css/statsPage.css";
@import "./css/replayer.css";
@import "./css/lobby.css";
@import "./css/table.css";
/* @import "./css/navbar.css"; */
@import "./css/homepage.css";


/* html, body, #root {
    height: 100vh;
} */

body {
    overflow: hidden !important;
    font-size: 14px;
    box-sizing: inherit;
    color: rgba(0,0,0,.87);
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif;
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    margin: 0;
    background-color:#312e2bb6;
    /* height: 100vh; */
}
#root {
    -webkit-tap-highlight-color: transparent;
    overflow: hidden !important;
    overscroll-behavior: none;
    max-width: 500px;
    height: 100%;
    position: absolute;
    left:50%;
    transform: translate(-50%,0);
    color-scheme: light only; 
    /* --black-eval: 50%; */
}

button {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
} 

img {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

#overlay {
    position: fixed;
    opacity: 0;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(var(--global-height) - 50px);
    background-color: rgba(0,0,0,0.5);
    pointer-events: none;
    transition: 200ms ease-in-out;
}

#overlay.active{
    opacity: 1;
    pointer-events: all;
    z-index: 999;
}

.modal-container-disconnect {
    position: absolute;
    top: calc(100vh * 0.5);
    left: 50%; 
    transform: translate(-50%, -52%) scale(0);
    -ms-transform: translate(-50%, -52%) scale(0);
    -webkit-transform: translate(-50%, -52%) scale(0); 
    margin-left: auto; 
    margin-right: auto;
    max-height: 20%;
    min-height: 50px;
    width: 80%;
    background-color: whitesmoke;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius:1.5rem;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    z-index: 10;
    transition: all 200ms ease-in-out;  
}

.modal-container-disconnect.active {
    transform: translate(-50%, -52%) scale(1);
    -ms-transform: translate(-50%, -52%) scale(1); 
    -webkit-transform: translate(-50%, -52%) scale(1); 
}

.modal-disconnect {
    position: relative;
    display: block;
    height: 50px;
    width: 100%;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    color: white;
}

.modal-disconnect.res-draw {
    background-color: #312e2b98;
}

.modal-result.text {
    position: relative;
    display: flex;
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 35px;
    width: 90%;
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
}

.hidden {
    height: 0 !important;
    width: 0 !important;
}



