.main-buttons {
    position: absolute;
    top: calc(50% + 60px);
    left: 50%;
    width: 100%;
    height: calc(100% - 60px) ;
    transform: translate(-50%, calc(-50% - 30px));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.main-button {
    background-color: #86a94b;
    border-radius: 0.25rem;
    border: none;
    color: white;
    font-weight: 700;
    min-width: 150px;
    min-height: 35px;
    width: 50%;
    height: 45px;
    position: relative;
}

div.online-count {
  position: absolute;
  top: 55px;
  left: 25%;
  width: 50%;
  text-align: center;
  color: white;
}

.main-button:disabled {
   background-color: #1f1e1b;
}

.main-button-logout {
    /* background-color: rgb(148, 13, 119); */
    background-color: #86a94b;
    border-radius: 0.25rem;
    border: none;
    color: white;
    font-weight: 700;
    min-width: 150px;
    min-height: 35px;
    width: 50%;
    height: 45px;
    position: relative;
}

.main-button-logout:disabled {
    background-color: #1f1e1b;
    display: block;
 }

 .main-message-container {
    position: relative;
    display: flex;
    height: fit-content;
    min-width: 150px;
    width: 96%;
    margin-top: 75px;
    margin-left: 2%;
    gap: 5px;
 }

 .main-message {
   background-color: #1f1e1b;
   position: relative;
   display: block;
   align-items: center;
   text-align: center;
   width: calc(100% - 95px);
   border-radius: 0.45rem;
   color: white;
   font-weight: 500;
   padding-top: 5px;
 }

 span.secondary-message {
   font-weight: 300;
   font-size: 12px;
 }

 a.email-link {
   color: white;
   text-decoration: none;
   font-weight: 700;
   font-size: 14px;
 }



